import { Injectable } from "@angular/core";
import { SignalRService } from "./signalr.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { HubConnection } from "@microsoft/signalr";
import { ApiResult } from "@shared/models";
import {PubSubService} from "@core/services/pub-sub.service";

@Injectable({ providedIn: "root" })
export class NotificationsService {
  private notificateCounter = new BehaviorSubject<number>(0);
  private notificateCounter$ = this.notificateCounter.asObservable();

  constructor(
    private toastr: ToastrService,
    private signalr: SignalRService,
    private http: HttpClient,
    private router: Router,
    private pubsub: PubSubService,
  ) {}

  public startListen() {
    const connection = this.signalr.connection;
    this.subscribeToNewNotifications(connection);
    connection.onclose(error => {
     this.unsubscribeFromNotifications();
    });
    connection.onreconnected(connectionId => {
      this.subscribeToNewNotifications(connection);
    });
  }

  public setReadState(param) {
    return this.http.post<ApiResult<boolean>>(
      "/api/notifications/updateNotificationStatus",
      param
    );
  }

  setNotificationCount(counter: number) {
    this.notificateCounter.next(counter);
    this.pubsub.publishEvent("notifications-changed");
  }

  getNotificateCount(): Observable<number> {
    return this.notificateCounter$;
  }

  getNotReadNotificatesCount() {
    return this.http.get<ApiResult<number>>(
      "/api/notifications/getNotReadNotificationsCount"
    );
  }

  setUpNotification(modifications) {
    return this.http.put<ApiResult<boolean>>("/api/notifications/settings", modifications);
  }

  getNotificationSettingsFromProfile() {
    return this.http.get<ApiResult<any>>(
      "/api/notifications/settings"
    );
  }

  private subscribeToNewNotifications(connection: HubConnection) {
    connection.on("ShowNewNotification", (caption: string, message: string) => {
      this.toastr.info(message, caption).onTap.subscribe(x => {
        this.router.navigate(["/cabinet/notifications"]);
      });
      this.setNotificationCount(this.notificateCounter.getValue() + 1);
    });
  }

  public unsubscribeFromNotifications(){
    this.signalr.connection.off("ShowNewNotification");
  }

  markAllNotificationsAsRead(): Observable<ApiResult<boolean>> {
    return this.http.patch<ApiResult<boolean>>('/api/notifications/mark-all-read', {});
  }

}
