import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ApiResultInterceptor,
  LoaderInterceptor,
  TokenInterceptor,
  ConfirmationInterceptor,
  LocaleInterceptor,
} from './interceptors';
import { ConsumerService } from './http/consumer.service';
import { AuthService, NcalayerService, NotificationsService } from './services';
import { SignalRService } from './services/signalr.service';
import { UtilsService } from './services/utils.service';
import { OrganizationService } from './http/organization.service';
import { EmployeesService } from './http/employees.service';
import { BusinessAccountService } from './http/business-account.service';
import { RegistrationService } from './http/registration.service';
import { StoresService } from './http/stores.service';
import { SupportService } from './http/support.service';
import { HasPermissionsDirective } from '@core/directives/HasPermissions.directive';
import { TrimPipe } from '@core/pipes/trim.pipe';
import { TrustedServiceCentersService } from '@core/http/trusted-service-centers.service';
import { InputTrimDirective } from '@core/directives/trim.directive';
import { UserSettingsService } from '@core/services/user-settings.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConfirmationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiResultInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    NotificationsService,
    ConsumerService,
    OrganizationService,
    AuthService,
    BusinessAccountService,
    EmployeesService,
    NcalayerService,
    RegistrationService,
    SignalRService,
    StoresService,
    SupportService,
    UtilsService,
    TrustedServiceCentersService,
    UserSettingsService,
  ],
  declarations: [
    HasPermissionsDirective,
    InputTrimDirective,
    TrimPipe,
  ],
  exports: [
    HasPermissionsDirective,
    InputTrimDirective,
    TrimPipe,
  ],
})
export class CoreModule {}
