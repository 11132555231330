import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {map} from 'rxjs/operators';
import {UtilsService} from './utils.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ApiResult, JWT, PermissionEnum} from "@shared/models";
import {JwtHelperService} from "@auth0/angular-jwt";
import {ActivatedRoute, Router} from "@angular/router";
import {PubSubService} from "@core/services/pub-sub.service";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public static readonly IssueNewTokenUrl: string = '/api/authenticate/issueNewToken';
  public readonly RefreshTokenKey: string = 'refresh-jwt';
  public readonly AccessTokenKey: string = 'auth-jwt';
  public readonly RoleClaimKey: string = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
  public readonly NameClaimKey: string = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';

  private _intervalId: any;
  private jwtHelper: JwtHelperService;
  private accessTokenRefreshTimeoutInMs = 2 * 60 * 1000;

  constructor(private cookieService: CookieService,
              private http: HttpClient,
              private utils: UtilsService,
              private pubsub: PubSubService,
              private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  loginByIinAndPassword(iin: string, password: string, organizationXin: string) {
    return this.http.post<ApiResult<JWT>>('/api/authenticate/byIinAndPassword', {
      credentials: {iin: iin, password: password},
      organizationXin: organizationXin
    })
      .pipe(map(this.handleAuthorizationResponse.bind(this)));
  }

  loginByEds(signedContent: string, organizationXin: string): Observable<ApiResult<JWT>> {
    return this.http.post<ApiResult<JWT>>('/api/authenticate/byEds', {
      credentials: {signedContent: signedContent},
      organizationXin: organizationXin
    })
      .pipe(map(this.handleAuthorizationResponse.bind(this)));
  }

  public handleAuthorizationResponse(result: ApiResult<JWT>): ApiResult<JWT> {
    if (result.data) {
      this.setJwt(result.data);
      this.setupTokenRefreshTimer();
      this.pubsub.publishEvent("login");
    }
    return result;
  }


  isLoggedIn(): Boolean {
    const token = this.getToken();
    return !!token;
  }

  getToken(): string {
    return localStorage.getItem(this.AccessTokenKey);
  }

  getPermissions(): PermissionEnum[] {
    const token = this.getToken();
    if (token == null) {
      return new Array<PermissionEnum>();
    }
    const decodedJwt = this.jwtHelper.decodeToken(token);
    const permissionIdList = <Array<string>>decodedJwt[this.RoleClaimKey];
    if (permissionIdList == null) {
      return [];
    }
    return permissionIdList.map(x => <PermissionEnum>parseInt(x));
  }

  logout(): void {
    console.log('Logging out...');
    this.clearAuthorizationData();
    this.dialog.closeAll();
    this.router.navigate(['auth', 'login']);
  }

  sendRestoreUrl(iin: string, email: string): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/authenticate/sendRestoreUrl', {
      iin: iin, email: email
    });
  }

  validateRestoreUrl(token: string): Observable<ApiResult<string>> {
    return this.http.get<ApiResult<string>>('/api/authenticate/ValidateRestoreUrl', {
      params: {token: token}
    });
  }

  resetPasswordByEmail(token: string, newPassword: string): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/authenticate/resetPasswordByEmail', {
      token: token,
      newPassword: newPassword
    });
  }

  resetPasswordByEds(signedContent: string, password: string): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/authenticate/resetPasswordByEds', {
      signedContent: signedContent,
      password: password
    });
  }

  confirmRegistration(iin: string, password: string, code: string): Observable<boolean> {
    return this.http.post<ApiResult<boolean>>('/api/employee/confirmregistration', {
      iin: iin,
      password: password,
      code: code
    }).pipe(map(x => x.data));
  }

  getRefreshToken() {
    return localStorage.getItem(this.RefreshTokenKey);
  }

  refreshToken(): Observable<ApiResult<JWT>> {
    const request = {"refreshtoken": this.getRefreshToken()};
    return this.http.post<ApiResult<JWT>>(AuthService.IssueNewTokenUrl, request)
      .pipe(
        map((resp) => {
            if (resp.data) {
              this.setJwt(resp.data);
              this.pubsub.publishEvent("refreshToken");
            }
            return resp;
          }
        ));
  }

  switchOrganization(xin: string): Observable<ApiResult<JWT>> {
    return this.http.post<ApiResult<JWT>>('/api/authenticate/switchOrganization', {xin: xin})
      .pipe(map(
        result => {
          if (result.data) {
            this.clearAuthorizationData();
            return this.handleAuthorizationResponse(result);
          }
          return result;
        }
      ));
  }

  public setJwt(data: JWT) {
    localStorage.setItem(this.AccessTokenKey, data.jwt);
    localStorage.setItem(this.RefreshTokenKey, data.refreshToken);
    this.cookieService.delete(this.AccessTokenKey);
    this.cookieService.set(this.AccessTokenKey, JSON.stringify(data.jwt), this.jwtHelper.getTokenExpirationDate(data.jwt), '/');
  }

  private mustBeRefreshedImmediately(): boolean {
    const now = Date.now();
    const expired = Number(this.getAccessTokenExpiration());
    if (now + this.accessTokenRefreshTimeoutInMs > expired) {
      return true;
    }
    return false;
  }

  public canBeRefreshed(): boolean {
    const refreshToken = this.getRefreshToken();
    if (refreshToken == null) {
      return false;
    }
    const refreshTokenExpirationDate = this.jwtHelper.getTokenExpirationDate(refreshToken);
    console.log('Refresh token expired at ' + refreshTokenExpirationDate);
    return Number(refreshTokenExpirationDate) > Date.now();
  }


  getAccessTokenExpiration(): Date {
    return this.jwtHelper.getTokenExpirationDate(this.getToken());
  }

  setupTokenRefreshTimer() {
    clearInterval(this._intervalId);
    if (!this.isLoggedIn()) {
      return;
    }
    let tokenRefreshTimeoutInMs = 100;
    if (!this.mustBeRefreshedImmediately()) {
      let tokenExpirationDateInMs = (Number(this.getAccessTokenExpiration()));
      tokenRefreshTimeoutInMs = tokenExpirationDateInMs - Date.now() - this.accessTokenRefreshTimeoutInMs;
      tokenRefreshTimeoutInMs = tokenRefreshTimeoutInMs + this.utils.getRandom(1000, this.accessTokenRefreshTimeoutInMs / 2);

      //  tokenRefreshTimeoutInMs = 1000 * 60;
    }
    console.log(`Next refresh attempt will be in ${tokenRefreshTimeoutInMs / 1000} seconds`);
    this._intervalId = setTimeout(() => {
      // if (!this.mustBeRefreshedImmediately()) {
      //   console.log('Already refreshed by other tab or service');
      //   // Means other tab or service have refreshed the Token
      //   this.setupTokenRefreshTimer();
      //   return;
      // }
      if (!this.canBeRefreshed()) {
        console.warn('Refresh token expired');
        this.logout()
        return;
      }
      this.refreshToken().subscribe(resp => {
        if (resp.error) {
          console.warn('Token refresh error: ' + resp.error.text);
          this.logout();
        } else {
          console.info('Token successfully refreshed');
          this.setupTokenRefreshTimer()
        }
      })
    }, tokenRefreshTimeoutInMs);
  }

  getAvailableProfilesToAuthorizeByIin(iin: string, password: string): Observable<ApiResult<any[]>> {
    return this.http.post<ApiResult<any[]>>('/api/profiles/getAvailableToAuthorize', {
      iinLogin: {
        iin: iin,
        password: password
      }
    });
  }

  getAvailableProfilesToAuthorizeByEds(signedContent: string) {
    return this.http.post<ApiResult<any[]>>('/api/profiles/getAvailableToAuthorize', {edsLogin: {signedContent: signedContent}});
  }

  isExistPermissions(requiredPermissions: PermissionEnum[]) {
    const profilePermissions = this.getPermissions();
    if (requiredPermissions == null || requiredPermissions.length == 0) {
      return true;
    }
    if (profilePermissions == null) {
      return false;
    }
    let isExist = false;
    requiredPermissions.forEach(x => {
      if (profilePermissions.includes(x)) {
        isExist = true;
        return;
      }
    });
    return isExist;
  }

  public getProfileIin(): string {
    const token = this.getToken();
    if (token == null) {
      return null;
    }
    let tokenData = this.jwtHelper.decodeToken(token);
    return tokenData.ProfileIin;
  }

  public getCurrentXin(): string {
    const token = this.getToken();
    if (token == null) {
      return null;
    }
    let tokenData = this.jwtHelper.decodeToken(token);
    return tokenData.OrganizationXin;
  }

  redirectToCabinet() {
    const redirect = this.route.snapshot.queryParams['returnUrl'] || "/cabinet";
    this.router.navigateByUrl(redirect);
  }

  private clearAuthorizationData() {
    localStorage.removeItem(this.AccessTokenKey);
    localStorage.removeItem(this.RefreshTokenKey);
    this.cookieService.delete(this.AccessTokenKey);
    clearInterval(this._intervalId);
    this.pubsub.publishEvent("logout")
  }

  sendTokenToExternalSystemByApiKey(apiKey: string): Observable<string> {
    return this.http.post<ApiResult<string>>(`/api/authenticate/external?X-API-KEY=${apiKey}`, null).pipe(map(x => x.data))
  }
}
