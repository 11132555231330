import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs'
import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {OrganizationService} from "@core/http/organization.service";
import {CashboxService} from "@core/http/cashbox.service";
import {AuthService} from "@core/services";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(private spinner: NgxSpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.method == 'GET'){
      return next.handle(req);
    }
    this.spinner.show();
    this.count++;
    return next.handle(req)
      .pipe(finalize(() => {
          this.count--;
          if (this.count <= 0) {
            this.count = 0;
            this.spinner.hide();
          }
        })
      );
  }
}
