<router-outlet></router-outlet>
<ngx-spinner bdOpacity=0.8
             bdColor="rgba(255,255,255,.8)"
             size="large"
             color="#A12E18"
             type="ball-clip-rotate"
             [fullScreen]="true">
  <p style="color: #A12E18"> <span translate>loading</span>... </p>
</ngx-spinner>
<ngx-loading-bar [color]="'#A12E18'" [includeSpinner]="false" ></ngx-loading-bar>
