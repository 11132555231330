import { Injectable, TemplateRef } from '@angular/core';
import { CellInfoModel } from '@app/shared/models/cellI-info.model';
import { LocalizationService } from './globaltranslate.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable()
export class UtilsService {
  constructor(private translate: LocalizationService) {}

  getRandom(min, max) {
    let rand = Math.random() * (max - min) + min;
    rand = Math.floor(rand);
    return rand;
  }
  getMin(prop: string, array: any[]) {
    return array.reduce((min, y) => {
      Math.min(min, y[prop]);
    });
  }

  getTicketType = (subType: CellInfoModel) => {
    switch (subType.value) {
      case 0:
        return this.translate.get('buy');
      case 1:
        return this.translate.get('return-buy');
      case 2:
        return this.translate.get('sell');
      case 3:
        return this.translate.get('return-sell');
    }
    return null;
  };

  exportHtmlToPDF(htmlElement: HTMLElement, title: string) {
    html2canvas(htmlElement, { scale: 4 }).then(
      (canvas) => {
        let docWidth = 400;
        let docHeight = (canvas.height * docWidth) / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');
        let doc = new jsPDF('p', 'px', 'a4');
        doc.addImage(contentDataURL, 'PNG', 20, 20, docWidth, docHeight);

        doc.save(title + '.pdf');
      }
    );
  }
}
