import {ApiResult, EmployeeForEdit, Employee, Permission, JWT} from 'src/app/shared/models';
import {EmployeeForRegistration, EditProfileRequest} from 'src/app/shared/models';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from "rxjs";

@Injectable()
export class EmployeesService {

  constructor(private http: HttpClient) {
  }

  getEmployee(iin: string) {
    return this.http.get<ApiResult<Employee>>('/api/employee/getForEdit', {params: {iin: iin}});
  }

  getCurrentProfileEditable() {
    return this.http.get<ApiResult<Employee>>('/api/profiles/getCurrentProfileEditable');
  }

  updateCurrentProfileFullName(profile: any): Observable<ApiResult<boolean>> {
    return this.http.patch<ApiResult<boolean>>('/api/profiles/updateCurrentProfileFullName', profile);
  }

  updateCurrentProfileEmail(profile: any): Observable<ApiResult<boolean>> {
    return this.http.patch<ApiResult<boolean>>('/api/profiles/updateCurrentProfileEmail', profile);
  }

  updateCurrentProfilePhone(profile: any): Observable<ApiResult<boolean>> {
    return this.http.patch<ApiResult<boolean>>('/api/profiles/updateCurrentProfilePhone', profile);
  }

  getPermissions() {
    return this.http.get<ApiResult<{ id: number, name: string, items: Permission[] }[]>>('/api/employee/getPermission', {});
  }

  registerNewHead(regData: any): Observable<ApiResult<JWT>> {
    return this.http.post<ApiResult<JWT>>('/api/employee/registerNewHead', regData);
  }

  register(employee: EmployeeForRegistration): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/employee/register', employee);
  }

  edit(employee: EmployeeForEdit): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/employee/edit', employee);
  }

  changePassword(param) {
    return this.http.put<ApiResult<boolean>>(
      "/api/employee/changePasswordWithCheck",
      param
    );
  }

  delete(iin: string) {
    return this.http.delete<ApiResult<boolean>>('/api/employee/delete', {params: {iin: iin}});
  }

  switchStatus(iin: string) {
    return this.http.post<ApiResult<boolean>>('/api/employee/block', {iin: iin});
  }


  doesProfileExist(iin: string): Observable<ApiResult<boolean>> {
    return this.http.get<ApiResult<boolean>>('/api/employee/doesExist', {params: {iin: iin}});
  }

  checkPermissionsCompatibility(iin: string, permissions: number[]): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>('/api/employee/checkPermissionsCompatibility', {
        iin: iin,
        permissions: permissions
    });
  }
}
