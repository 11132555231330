import {TranslateLoader} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
export function TranslationLoaderFactory() {
  return new TranslateLoader2();
}
export class TranslateLoader2 extends TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }

}
