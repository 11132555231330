import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PasswordConfirmationComponent } from "../../shared/components/password-confrimation/password-confirmation.component";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}
  openDialog(): Observable<string> {
    const dialogRef = this.dialog.open(PasswordConfirmationComponent, {});
    return dialogRef.afterClosed();
  }
}
