import {Inject, Injectable} from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {AuthService} from "../services";
import {Observable, of} from "rxjs";
import {environment} from '../../../environments/environment';
import {catchError, map,} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {ApiErrorCode, ApiResult} from "@shared/models";
@Injectable()
export class ApiResultInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private toastr: ToastrService
  ) {
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
     const apiReq = req.clone({ url: `${environment.apiUrl}${req.url}` });
    return next
      .handle(apiReq)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (!(event instanceof HttpResponse)) {
            return event;
          }
          const response = <HttpResponse<any>>event;
          if (response.status !== 200) {
            return event;
          }
          const apiResult = <ApiResult<any>>response.body;
          if (apiResult && apiResult.error) {
            switch (apiResult.error.code) {
              case ApiErrorCode.UnknownError:
              case ApiErrorCode.ValidationError: {
                this.toastr.error(apiResult.error.text);
                break;
              }
            }
          }
          return event;
        }),
        catchError(error => {
          if (error.status === 400) {
            const errors = error.error.errors;
            const properties = Object.getOwnPropertyNames(errors);
            const firstPropertyWithError = errors[properties[0]];
            this.toastr.error(firstPropertyWithError[0]);
          }

          return of(error);
        })
      );

  }
}
