import {Injectable} from "@angular/core";

@Injectable()

export class UserSettingsService {
  constructor () {}

  private getUserSettings(): object {
    const userSettings = localStorage.getItem('user-settings');
    if (userSettings == null) {
      return {};
    }
    return JSON.parse(userSettings);
  }

  private getSettingByName<T>(name: string, defaultValue: T|null):T|null {
    const userSettings = this.getUserSettings();
    const value = userSettings[name];
    if (value == null) {
      return defaultValue;
    }
    return value;
  }

  private setUserSettingByName(name: string, value: any) {
    const userSettings = this.getUserSettings();
    userSettings[name] = value;
    localStorage.setItem('user-settings', JSON.stringify(userSettings));
  }

  public getMenuState(): boolean {
    return this.getSettingByName('menuState', true);
  }

  public setMenuState(state: boolean) {
    this.setUserSettingByName('menuState', state);
  }

  public getDefaultProfile(iin: string): string {
    return this.getSettingByName('defaultProfile' + iin, null);
  }

  public setDefaultProfile(profileIin: string, profileXin: string) {
    this.setUserSettingByName('defaultProfile' + profileIin, profileXin);
  }

}
