import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { ApiResult } from "src/app/shared/models";
import { FoundTicketsResponse } from "@app/shared/models/found-tickets-response.model";
import { format, formatISO } from "date-fns";

export const GetTicketUrl : string = '/api/tickets' ;

@Injectable()
export class ConsumerService {
  constructor(private http: HttpClient) {}

  searchTicket(cashboxRN:string, ticketNumber : string, ticketDate?:Date|null) {
    let queryParams = new HttpParams({fromObject:{
      registrationNumber: cashboxRN,
      ticketNumber: ticketNumber
    }})
    if(ticketDate!=null){
      queryParams = queryParams.append('ticketDate', format(ticketDate,'yyyy-MM-dd'))
    }
    return this.http.get<ApiResult<FoundTicketsResponse>>(GetTicketUrl, { params: queryParams });
  }
}
