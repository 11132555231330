import {ApiResult,} from 'src/app/shared/models';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class SupportService {
  constructor(private http: HttpClient) {
  }

  createServiceCenter(xin) {
    return this.http.post<ApiResult<boolean>>('/api/support/createServiceCenter', {xin: xin});
  }

  removeServiceCenter(xin) {
    return this.http.delete<ApiResult<boolean>>('/api/support/delete', {params: {xin: xin}});
  }

  changeOverdraft(param) {
    return this.http.put<ApiResult<boolean>>('/api/support/changeOverdraft', param);
  }

  getInfoSc(xin) {
    return this.http.get<ApiResult<any>>('/api/support/getServiceCenter',
      {params: {xin: xin}});
  }

  getMessageDetailContent(cashboxId: number, id: string): Observable<ApiResult<any>> {
    return this.http.get<ApiResult<any>>('/api/support/getMessageDetailContent', {
      params: {
        cashboxId: cashboxId.toString(),
        id: id
      }
    });
  }

  addServices(cashboxId: number, tariffPlan: string ) {
    return this.http.post<ApiResult<boolean>>("/api/support/addServices", {cashbox: cashboxId, tariffPlan: tariffPlan});
  }

  editUserInfo(data: {id: number, fullName: string, email: string, phoneNumber: string }) {
    return this.http.patch<ApiResult<boolean>>("/api/support/editUserInfo", data);
  }

  editOrganizationInfo(xin:string, data: any) {
    return this.http.patch<ApiResult<boolean>>(`/api/support/organizations/${xin}`, data);
  }

  resendKgdRequest(id: string) {
    return this.http.post<ApiResult<any>>('/api/support/resendHungKgdRequest', {id: id});
  }

  uploadDraftsFromExcel(organizationXin:string, asEmployeeIin:string, cashboxModelCode:string, tsvContent: string) : Observable<boolean>{
    return this.http.post<ApiResult<any>>(`/api/support/organizations/${organizationXin}/upload-drafts`, {
      asEmployee:asEmployeeIin,
      cashboxModelCode:cashboxModelCode,
      tabSeparatedDrafts:tsvContent
    }).pipe(map(x=>x.data));
  }
}
