import {Injectable} from "@angular/core";
import {ApiResult} from "@shared/models";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class TrustedServiceCentersService {
  constructor(
    private http: HttpClient,
  ) {
  }

  deleteServiceCenter(xin: string) {
    return this.http.delete<ApiResult<boolean>>(`/api/clients/current/service-centers/${xin}`);
  }

  deleteServiceCenterByCashboxes(xin: string) {
    return this.http.delete<ApiResult<boolean>>(`/api/clients/current/service-centers/${xin}/by-cashboxes`);
  }

  createTrustedServiceCenter(data: any): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<any>>('/api/clients/current/service-centers', data);
  }

  updateTrustedServiceCenter(xin: string, data: any): Observable<ApiResult<boolean>> {
    return this.http.put<ApiResult<any>>(`/api/clients/current/service-centers/${xin}`, data);
  }

  getSelectedCashboxesByStore(serviceCenterXin: string, storeId: number): Observable<ApiResult<Array<number>>> {
    return this.http.get<ApiResult<Array<number>>>(`/api/clients/current/service-centers/${serviceCenterXin}/stores/${storeId}/cashboxes`);
  }

  getAccesses(serviceCenterXin: string):Observable<ApiResult<{ allowCreateStores:boolean, stores: Array<{id:number, cashboxes:Array<number>}> }>> {
    return this.http.get<ApiResult<any>>(`/api/clients/current/service-centers/${serviceCenterXin}`);

  }
}
