import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import {AuthService, LocalizationService} from "@core/services";
import { Observable, throwError } from "rxjs";
import { ConfirmationDialogService } from "src/app/core/services/confirmation-dialog.service";
import { first, mergeMap } from "rxjs/operators";

@Injectable()
export class ConfirmationInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private dialogService: ConfirmationDialogService,
    private translate: LocalizationService) {}

  private urls = ["api/support/changeOverdraft"];

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const index = this.urls.findIndex(x => request.url.includes(x));
    if (index < 0) {
      return next.handle(request);
    }
    const dialog = this.dialogService.openDialog();
    const pipe = dialog.pipe(
      first(),
      mergeMap(enteredPassword => {
        if (enteredPassword == null) {
          return throwError(this.translate.get("password-confirmation-cancelled"));
        }
        const req = request.clone({
          setHeaders: {
            Password: enteredPassword
          }
        });
        return next.handle(req);
      })
    );
    return pipe;
  }
}
