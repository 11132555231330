import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiResult} from "@shared/models";

@Injectable()
export class StoresService {
  constructor(
    private http: HttpClient,
  ) { }
  createStore(data: any) {
    return this.http.post<ApiResult<any>>('/api/stores', data);
  }
  getStoreById(id: number) {
    return this.http.get<ApiResult<any>>(`/api/stores/${id}`)
  }
  changeStore(id:number, param: any) {
    return this.http.put<ApiResult<any>>(`/api/stores/${id}`, param);
  }
  delete(id: number) {
    return this.http.delete<ApiResult<boolean>>(`/api/stores/${id}`);
  }

  getSelectedCashboxesCountForServiceCenter(serviceCenterXin: string, storesIds: number[]) {
    const query = new HttpParams()
      .append("serviceCenterXin", serviceCenterXin)
      .append("storeIds", storesIds.join(','))
    return this.http.get<ApiResult<any>>(`/api/clients/current/service-centers/accessible-cashboxes-count-by-stores`,
      {params: query});
  }
}
