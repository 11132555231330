import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Validators, UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
@Component({
  styleUrls: ["./password-confirmation.component.scss"],
  templateUrl: "./password-confirmation.component.html",
  selector: "app-password-confirmation-dialog"
})
export class PasswordConfirmationComponent {
  public form: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<PasswordConfirmationComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.form = this.fb.group({
      password: ["", [Validators.required]]
    });
  }
  onConfirm() {
    const pass = this.form.get("password").value;
    this.dialogRef.close(pass);
  }
  onCancel() {
    this.dialogRef.close(null);
  }
}
