import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TicketViewComponent} from "./components/ticket-view-component/ticket-view.component";
import {PageNotFoundComponent} from "./components/page-not-found/page-not-found.component";
import {RouterModule} from "@angular/router";
import {MatDialogModule} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {ShortOrgNamePipe} from "@shared/pipes/short-org-name.pipe";
import {PasswordConfirmationComponent} from "./components/password-confrimation/password-confirmation.component";
import {ReactiveFormsModule} from "@angular/forms";
import {ControlMessages} from "@shared/components/control-messages/control-messages.component";
import {AlertDialogComponent} from "@shared/components/alert-dialog/alert-dialog.component";
import {SpinnerComponent} from '@shared/components/spinner/spinner.component';
import {AddCashboxServicesComponent} from "@shared/components/add-cashbox-services/add-cashbox-services.component";
import {TranslateModule} from "@ngx-translate/core";
import {ColumnChooserButton} from "@shared/components/column-chooser-button/column-chooser-button";
import {DxDataGridModule} from "devextreme-angular/ui/data-grid";
import {DxSelectBoxModule} from "devextreme-angular/ui/select-box";
import {DxRadioGroupModule} from "devextreme-angular/ui/radio-group";
import {DxCheckBoxModule, DxPopoverModule} from "devextreme-angular";
import {InisProblemsComponent} from "@shared/components/inis-problems/inis-problems.component";;
import { ReportValuesComponent } from './components/label-icon-value/reports-value.component'

@NgModule({
    declarations: [
        TicketViewComponent,
        PageNotFoundComponent,
        ConfirmationDialogComponent,
        ShortOrgNamePipe,
        PasswordConfirmationComponent,
        ControlMessages,
        AlertDialogComponent,
        SpinnerComponent,
        AddCashboxServicesComponent,
        ColumnChooserButton,
        InisProblemsComponent,
        ReportValuesComponent
    ],
    exports: [
        TicketViewComponent,
        PageNotFoundComponent,
        ConfirmationDialogComponent,
        ShortOrgNamePipe,
        PasswordConfirmationComponent,
        ControlMessages,
        AlertDialogComponent,
        SpinnerComponent,
        AddCashboxServicesComponent,
        ColumnChooserButton,
        InisProblemsComponent,
        ReportValuesComponent
    ],
    imports: [CommonModule, RouterModule, MatDialogModule, ReactiveFormsModule, DxDataGridModule, TranslateModule,
        DxSelectBoxModule, DxRadioGroupModule, DxCheckBoxModule, DxPopoverModule]
})
export class SharedModule {
}
