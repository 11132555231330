import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiResult} from "../../shared/models";
import {Observable} from "rxjs";
import {UtilsService} from "@core/services/utils.service";

@Injectable()
export class OrganizationService {
  public static readonly ShortInfoUrl : string = '/api/organization/getShortInformation';
  constructor(private http: HttpClient) {
  }

  getOrganizationShortInformation() {
    return this.http.get<ApiResult<OrganizationShortInformation>>(OrganizationService.ShortInfoUrl);
  }

  getMoreDetails() {
    return this.http.get<ApiResult<any>>('/api/organization/getMoreDetails');
  }

  getRegistrationInfo(id: string):Observable<ApiResult<any>> {
    return this.http.get<ApiResult<any>>("/api/Organization/GetRegistrationInfo", {
      params: {id: id}
    })
  }

  changeOrganizationDetails(val) {
    return this.http.put<ApiResult<boolean>>('/api/organization/changeDetails', val);
  }

  getInvoices(from: string, to: string): Observable<ApiResult<Array<any>>> {
    return this.http.get<ApiResult<Array<any>>>(`/api/balance/getInvoices?from=${from}&to=${to}`);
  }

  downloadInvoice(number: string): Observable<Blob> {
    return this.http
      .get<Blob>(`/api/balance/downloadInvoice`, {
        params: {number: number},
        responseType: "blob" as "json"
      })
  }

  getLegalInformation(xin: string): Observable<ApiResult<OrganizationLegalInformation>> {
    return this.http.get<ApiResult<OrganizationLegalInformation>>(`/api/organization/getLegalInformation?xin=${xin}`);
  }
}

export class OrganizationShortInformation {
  xin: string;
  accountBalance: number;
  reservedBalance: number;
  shortName: string;
  employeesCount: number;
  storesCount: number;
}


export class OrganizationLegalInformation {
  fullName: string;
  economicActivityCode: string;
  katoCode: string;
}
