import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionState, LogLevel } from '@microsoft/signalr';
import { AuthService } from "./auth.service";
import {PubSubService} from "@core/services/pub-sub.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class SignalRService {
  public connection: signalR.HubConnection;

  constructor(
    private auth: AuthService,
    private pubsub: PubSubService
  ) {
    this.buildConnection();
    let tokenExpirationDateInMs = Number(this.auth.getAccessTokenExpiration());
    if (tokenExpirationDateInMs - Date.now() > 0) {
      this.connect();
    }
    this.pubsub.subscribe("login", () => {
      this.connect();
    });
    this.pubsub.subscribe("logout", () => {
      this.disconnect();
    });
    this.pubsub.subscribe("refreshToken", () => {
      this.disconnect();
      this.connect();
    });
  }

  private buildConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl+"/notification", {
        accessTokenFactory: () => {
          var token = this.auth.getToken();
          return token;
        }
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();
  }

  private connect() {
    if (this.connection.state == HubConnectionState.Connected) {
      return;
    }
    let connection = this.connection;
    connection
      .start()
      .then(() => {
        console.log("Connected with connectionId " + connection.connectionId);
      })
      .catch(function (err) {
        return console.error(err.toString());
      });
    this.connection.onclose(error => {
      if (error) {
        console.log(error.message);
      }
    });
  }

  private disconnect() {
    if (
      this.connection == null ||
      this.connection.state == HubConnectionState.Connected
    ) {
      return;
    }
    console.log(
      "Disconnection with connectionId " + this.connection.connectionId
    );
    this.connection.stop();
  }
}
