import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResult, CashboxPaymentStatistics, ScCashboxPaymentStatistics} from "../../shared/models";
import {BasicIndicatorsModel} from "@shared/models/basic-indicators.model";
import {OperationTypeAmount} from "@shared/models/operationTypeAmount";

@Injectable()
export class BusinessAccountService {
  constructor(private _http: HttpClient) {
  }

  addServices(param) {
    return this._http.post<ApiResult<boolean>>("/api/balance", param);
  }

  cancelCashboxServiceByClient(serviceNumber: string): Observable<ApiResult<boolean>> {
    return this._http.delete<ApiResult<boolean>>(`/api/balance/${serviceNumber}`);
  }

  getRegistrationDraft(id: number) {
    return this._http.get<ApiResult<any>>(
      `/api/service-centers/current/drafts/${id}`
    );
  }

  saveDraft(draft: any): Observable<ApiResult<any>> {
    return this._http.post<ApiResult<any>>("/api/service-centers/current/drafts", draft);
  }

  editDraft(id: number, organizationXin: string, data: any): Observable<ApiResult<any>> {
    return this._http.put<ApiResult<any>>(`/api/service-centers/current/drafts/${id}`,
      {id, organizationXin, data}
    );
  }

  deleteDraft(id: any) {
    return this._http.delete<ApiResult<boolean>>(`/api/service-centers/current/drafts/${id}`);
  }

  addServicesByServiceCenter(param): Observable<ApiResult<boolean>> {
    return this._http.post<ApiResult<boolean>>(
      "/api/service-centers/current/services",
      param
    );
  }

  cancelCashboxService(serviceNumber: string): Observable<ApiResult<boolean>> {
    return this._http.delete<ApiResult<boolean>>(`/api/service-centers/current/services/${serviceNumber}`);
  }

  getOrganizationBasicIndicators() {
    return this._http.get<ApiResult<BasicIndicatorsModel>>(
      "/api/statistics/basicIndicators"
    );
  }

  getOrganizationAmountsByOperationTypes() {
    return this._http.get<ApiResult<OperationTypeAmount[]>>(
      "/api/statistics/amountsByOperationTypes"
    );
  }

  getStatisticsAsSc(): Observable<ApiResult<ScCashboxPaymentStatistics>> {
    return this._http.get<ApiResult<ScCashboxPaymentStatistics>>("/api/service-centers/current/statistics");
  }

  getStatisticsAsClient(): Observable<ApiResult<CashboxPaymentStatistics>> {
    return this._http.get<ApiResult<CashboxPaymentStatistics>>("/api/balance/getStatistics");
  }

  accessByClient(clientXin: string): Observable<ApiResult<{ allowCreateStores: boolean }>> {
    return this._http.get<ApiResult<{ allowCreateStores: boolean }>>(
      `/api/service-centers/current/clients/${clientXin}/accesses`
    );
  }

  createStoreForClient(clientXin: string, request: any): Observable<ApiResult<number>> {
    return this._http.post<ApiResult<number>>(`/api/service-centers/current/clients/${clientXin}/stores`, request);
  }

  setupProlongation(changes: Array<{ id: number, sourceXin: string, serviceOwnerType: number }>): Observable<ApiResult<boolean>> {
    return this._http.post<ApiResult<boolean>>(`/api/services/prolongation-settings`, {items: changes});
  }

  setupProlongationAsServiceCenter(changes: Array<{ id: number, sourceXin: string, serviceOwnerType: number }>): Observable<ApiResult<boolean>> {
    return this._http.post<ApiResult<boolean>>(`/api/service-center-services/prolongation-settings`, {items: changes});
  }
}
