import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiResult, JWT} from "@shared/models";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {OrganizationExistingEnum} from "@shared/models/organizationExistingEnum";

@Injectable()
export class RegistrationService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getTaxationType() {
    return this.http.get<ApiResult<any>>('/api/reference/taxation-types');
  }

  public register(regData: any): Observable<ApiResult<JWT>> {
    return this.http.post<ApiResult<JWT>>('/api/organization/register', regData);
  }
  public confirmRegister(regData: any): Observable<ApiResult<JWT>> {
    return this.http.post<ApiResult<JWT>>('/api/organization/confirmRegister', regData);
  }

  public doesOrganizationExist(organizationXin: string): Observable<OrganizationExistingEnum> {
    return this.http.get<ApiResult<OrganizationExistingEnum>>(`/api/organization/doesExist/${organizationXin}`).pipe(map(x => x.data));
  }

  public doesProfileExist(iin: string): Observable<boolean> {
    return this.http.get<ApiResult<boolean>>(`/api/profiles/doesExist/${iin}`).pipe(map(x => x.data));
  }

  public doesProfileExistInOrganization(iin: string, xin: string): Observable<boolean> {
    return this.http.get<ApiResult<boolean>>(`/api/profiles/doesExistInOrganization?iin=${iin}&xin=${xin}`).pipe(map(x => x.data));
  }

  public sendSmsCode(xin: string, phone: string): Observable<ApiResult<boolean>> {
    return this.http.post<ApiResult<boolean>>(`/api/profiles/sendPhoneConfirmationSms?phone=${phone}`, {});
  }

  public verifySmsCode(xin: string, phone: string, smsCode: string): Observable<ApiResult<boolean>> {
    return this.http.get<ApiResult<boolean>>(`/api/profiles/verifyPhoneConfirmationSms?phone=${phone}&smsCode=${smsCode}`, {});
  }

  public readCertificateInformation(signedXml: string): Observable<ApiResult<any>> {
    return this.http.post<ApiResult<any>>(`/api/organization/getCertificateInformation`, {signedContent: signedXml});
  }

  public getUnconfirmedOrganizationRegInfo(organizationXin: string): Observable<ApiResult<any>> {
    return this.http.get<ApiResult<any>>(`/api/organization/GetUnconfirmedOrganizationInfo/${organizationXin}`);
  }
}
