import { ApiError } from './apierror.model';

export class ApiResult<T> {

    data: T;
    error: ApiError;

    constructor() {  }

    hasError(): boolean { return this.error != null; }
}
