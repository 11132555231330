<div class="not-found d-flex">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 offset-lg-4">
        <div class="error d-flex">
          <i class="icon-error"></i>
          <div class="error__big" translate>notFoundPage</div>
          <span class="error__text">notPage</span>
          <a [routerLink]="['/']" class="btn-white" translate>button-toMain</a>
        </div>
      </div>
    </div>
  </div>
</div>
