import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrModule } from 'ngx-toastr';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeKk from '@angular/common/locales/kk';
import 'devextreme/localization/globalize/date';
import supplemental from 'devextreme-cldr-data/supplemental.json';
import kkCldrData from 'devextreme-cldr-data/kk.json';
import ruCldrData from 'devextreme-cldr-data/ru-KZ.json';
import Globalize from 'globalize';

import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AuthService, LocalizationService } from '@core/services';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PubSubService } from '@core/services/pub-sub.service';
import { loadMessages } from 'devextreme/localization';
import dxRuMessages from 'devextreme/localization/messages/ru.json';
import dxKkMessages from 'src/assets/i18n/devextreme/kk-KZ.json';
import { TranslationLoaderFactory } from '@app/translationLoaderFactory';
import dxDataGrid from 'devextreme/ui/data_grid';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollingModule,
    LoadingBarRouterModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
    CoreModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    HttpClient,
    CookieService,
    { provide: MatDialogRef, useValue: {} },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (translate: LocalizationService) =>
        translate.getCurrentLocaleName(),
    },
    PubSubService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private auth: AuthService,
    private localizationService: LocalizationService
  ) {
    loadMessages(dxKkMessages);
    loadMessages(dxRuMessages);

    registerLocaleData(localeRu, 'ru-KZ');
    registerLocaleData(localeKk, 'kk');
    this.auth.setupTokenRefreshTimer();
    Globalize.load(supplemental, kkCldrData, ruCldrData);
    this.localizationService.initialize();
    dxDataGrid.defaultOptions({
      options: {
        syncLookupFilterValues: false,
      },
    });
  }
}
