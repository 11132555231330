import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '@core/services';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ApiErrorCode, ApiResult, JWT } from '@shared/models';
import { ApiError } from '@shared/models/apierror.model';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);

  constructor(public auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.injectToken(request);
    const accessExpired = this.auth.getAccessTokenExpiration() < new Date();
    const shouldProcessRequest = !this.auth.isLoggedIn() || !accessExpired;
    if (shouldProcessRequest) {
      return next.handle(request).pipe(
        mergeMap((response) => {
          if (response instanceof HttpErrorResponse) {
            const errorResponse = <HttpErrorResponse>response;
            if (errorResponse.status == 401) {
              return this.refreshTokenAndContinue(request, next);
            }
          }
          return of(response);
        })
      );
    }

    if (request.url.indexOf('issueNewToken') === -1) {
      return this.refreshTokenAndContinue(request, next);
    }

    this.refreshTokenInProgress = true;
    return next.handle(request).pipe(
      filter(
        (x) => x instanceof HttpResponse || x instanceof HttpErrorResponse
      ),
      map((response: HttpEvent<any>) => {
        if (response instanceof HttpErrorResponse) {
          this.auth.logout();
          return response;
        }
        if (response instanceof HttpResponse) {
          const authResponse = <HttpResponse<ApiResult<JWT>>>response;
          if (authResponse.body && authResponse.body.data) {
            this.auth.setJwt(authResponse.body.data);
            console.log('Trigger queue');
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(this.auth.getToken());
          } else {
            this.auth.logout();
          }
        }
        return response;
      })
    );
  }

  private injectToken(request: HttpRequest<any>): HttpRequest<any> {
    if (this.auth.isLoggedIn()) {
      const accessToken = this.auth.getToken();
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return request;
  }

  private refreshTokenAndContinue(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.auth.canBeRefreshed()) {
      this.auth.logout();
      const result = new ApiResult();
      result.error = new ApiError(2, 'Срок действия сессии истёк');
      return of(new HttpResponse({ body: result }));
    }
    console.log(`refreshTokenInProgress - ${this.refreshTokenInProgress}`);

    if (this.refreshTokenInProgress) {
      console.log(`Queue request to ${request.url}`);
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap((res) => {
          console.log(`Pop request to ${request.url} from queue and send`);
          return next.handle(this.injectToken(request));
        }),
        catchError((x: HttpErrorResponse) =>
          this.refreshTokenAndContinue(request, next)
        )
      );
    }

    this.refreshTokenInProgress = true;
    console.log(`Queue request to ${request.url} and initiate Token Refresh`);
    this.refreshTokenSubject.next(null);
    return this.auth.refreshToken().pipe(
      switchMap((authResponse: ApiResult<any>) => {
        console.log(`Token refresh result is ${authResponse.data != null}`);
        if (authResponse.error) {
          if (authResponse.error.code == ApiErrorCode.Unauthorized) {
            this.auth.logout();
          }
          return next.handle(this.injectToken(request)); //TODO надо возвращать ApiResponse
        }
        return next
          .handle(this.injectToken(request))
          .pipe(
            catchError((x: HttpErrorResponse) =>
              this.refreshTokenAndContinue(request, next)
            )
          );
      })
    );
  }
}
