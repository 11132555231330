import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {locale} from "devextreme/localization";
import {LocaleEnum} from "@shared/models/locale.enum";
import {CookieService} from "ngx-cookie-service";
import Globalize from "globalize";


@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  private defaultLocale = LocaleEnum.Ru;
  private key = "locale";

  constructor(private translate: TranslateService, private cookie: CookieService) {
  }

  initialize() {
    const localeName = this.getCurrentLocaleName();
    this.setModulesLocale(localeName);
  }


  private setModulesLocale(localeName: string): void {
    locale(localeName);
    Globalize.locale(localeName);
    this.translate.use(localeName);
    this.cookie.set('.AspNetCore.Culture', `c=${localeName}|uic=${localeName}`, {path: '/'});
  }

  get(key: string | string[], interpolateParams?: Object): string {
    const translation = this.translate.instant(key, interpolateParams);
    if (translation) {
      return translation;
    }
    if (typeof key == 'string') {
      return key;
    }
    return 'NO-TRANSLATE';
  }

  private get localeFromStorage(): LocaleEnum | null {
    const localeName = localStorage.getItem(this.key);
    for (let locale in LocaleEnum) {
      if (LocaleEnum[locale] == localeName) {
        return LocaleEnum[locale];
      }
    }
    return null;
  }

  changeLocale(lang: LocaleEnum) {
    localStorage.removeItem(this.key);
    localStorage.setItem(this.key, lang);
    this.setModulesLocale(lang);

    location.reload();
  }

  getCurrentLocale(): LocaleEnum {
    if (this.localeFromStorage == null) {
      localStorage.setItem(this.key, this.defaultLocale.toString());
      return this.defaultLocale;
    }
    return this.localeFromStorage;
  }

  getCurrentLocaleName(): string {
    return this.getCurrentLocale();
  }

}
