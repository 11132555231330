import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from "@core/guards/authentication-guard.service";
import {PageNotFoundComponent} from "@shared/components/page-not-found/page-not-found.component";
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('@app/modules/start/start.module').then(m => m.StartModule),
  },
  {
    path: 'consumer',
    loadChildren: () => import('@app/modules/consumer/consumer.module').then(m => m.ConsumerModule),
  },
  {
    path: 'cabinet',
    canLoad: [AuthenticationGuard],
    loadChildren: () => import('@app/modules/cabinet-layout/cabinet-layout.module').then(m => m.CabinetLayoutModule),
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
