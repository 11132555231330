<h3 translate>enterPassword</h3>
<form [formGroup]="form" (ngSubmit)="onConfirm()">
  <div class="form-group">
    <label translate>password</label>
    <input
      formControlName="password"
      type="password"
      class="form-control"/>
  </div>
  <div class="btn-grp">
    <button
      (click)="onCancel()"
      type="button"
      class="btn-silver"
      translate
    >
      button-cancel
    </button>
    <button
      type="submit"
      class="btn-primary"
      [disabled]="form.invalid"
      translate
    >
      button-confirm
    </button>
  </div>
</form>
